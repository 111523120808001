@import "src/styles/common.scss";

.header-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    display: flex;
    height: calc(100vh - 70px);
    padding-bottom: 70px;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.header-picture {
    max-height: 250px;
}

.header-title {
    display: flex;
    text-align: left;
    margin-bottom: 30px;
}

.snake-container {
    position: absolute;
    height: 47%;
    width: 54%;
    top: 29.5%;
    left: 23%;
    background-color: black;
    z-index: 1;
}

.column-right {
    flex: 50%;
    justify-content: flex-end;
    display: flex;
    align-items: center;
    position: relative;
}

.tv-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 25px;
}

.tv-wrapper {
    position: relative;
    filter: drop-shadow(10px 10px 0px var(--theme-drop-shadow));
}

.tv-background {
    position: absolute;
    margin-left: 10px;
    top: 4%;
    height: 40%;
}

.tv {
    position: relative;
    z-index: 1;
    cursor: pointer;
}

.tv-image-animation {
    z-index: 0;
    position: absolute;
    top: 29%;
    left: 23%;
    height: 47%;
    -webkit-animation: flicker-in-1 5s linear both;
    animation: flicker-in-1 5s linear both;
    transition: 2s ease-in;
}

.tv-text {
    color: white;
    position: absolute;
    top: 67%;
    left: 34%;
    font-size: 20px;
    -webkit-animation: flicker-1 5s linear infinite both;
    animation: flicker-1 5s linear infinite both;
}

@-webkit-keyframes flicker-1 {
    0%,
    100% {
        opacity: 1;
    }
    41.99% {
        opacity: 1;
    }
    42% {
        opacity: 0;
    }
    43% {
        opacity: 0;
    }
    43.01% {
        opacity: 1;
    }
    47.99% {
        opacity: 1;
    }
    48% {
        opacity: 0;
    }
    49% {
        opacity: 0;
    }
    49.01% {
        opacity: 1;
    }
}
@keyframes flicker-1 {
    0%,
    100% {
        opacity: 1;
    }
    41.99% {
        opacity: 1;
    }
    42% {
        opacity: 0;
    }
    43% {
        opacity: 0;
    }
    43.01% {
        opacity: 1;
    }
    47.99% {
        opacity: 1;
    }
    48% {
        opacity: 0;
    }
    49% {
        opacity: 0;
    }
    49.01% {
        opacity: 1;
    }
}

@-webkit-keyframes flicker-in-1 {
    0% {
        opacity: 0;
    }
    10% {
        opacity: 0;
    }
    10.1% {
        opacity: 1;
    }
    10.2% {
        opacity: 0;
    }
    20% {
        opacity: 0;
    }
    20.1% {
        opacity: 1;
    }
    20.6% {
        opacity: 0;
    }
    30% {
        opacity: 0;
    }
    30.1% {
        opacity: 1;
    }
    30.5% {
        opacity: 1;
    }
    30.6% {
        opacity: 0;
    }
    45% {
        opacity: 0;
    }
    45.1% {
        opacity: 1;
    }
    50% {
        opacity: 1;
    }
    55% {
        opacity: 1;
    }
    55.1% {
        opacity: 0;
    }
    57% {
        opacity: 0;
    }
    57.1% {
        opacity: 1;
    }
    60% {
        opacity: 1;
    }
    60.1% {
        opacity: 0;
    }
    65% {
        opacity: 0;
    }
    65.1% {
        opacity: 1;
    }
    75% {
        opacity: 1;
    }
    75.1% {
        opacity: 0;
    }
    77% {
        opacity: 0;
    }
    77.1% {
        opacity: 1;
    }
    85% {
        opacity: 1;
    }
    85.1% {
        opacity: 0;
    }
    86% {
        opacity: 0;
    }
    86.1% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}
@keyframes flicker-in-1 {
    0% {
        opacity: 0;
    }
    10% {
        opacity: 0;
    }
    10.1% {
        opacity: 1;
    }
    10.2% {
        opacity: 0;
    }
    20% {
        opacity: 0;
    }
    20.1% {
        opacity: 1;
    }
    20.6% {
        opacity: 0;
    }
    30% {
        opacity: 0;
    }
    30.1% {
        opacity: 1;
    }
    30.5% {
        opacity: 1;
    }
    30.6% {
        opacity: 0;
    }
    45% {
        opacity: 0;
    }
    45.1% {
        opacity: 1;
    }
    50% {
        opacity: 1;
    }
    55% {
        opacity: 1;
    }
    55.1% {
        opacity: 0;
    }
    57% {
        opacity: 0;
    }
    57.1% {
        opacity: 1;
    }
    60% {
        opacity: 1;
    }
    60.1% {
        opacity: 0;
    }
    65% {
        opacity: 0;
    }
    65.1% {
        opacity: 1;
    }
    75% {
        opacity: 1;
    }
    75.1% {
        opacity: 0;
    }
    77% {
        opacity: 0;
    }
    77.1% {
        opacity: 1;
    }
    85% {
        opacity: 1;
    }
    85.1% {
        opacity: 0;
    }
    86% {
        opacity: 0;
    }
    86.1% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}

.short-paragraph {
    width: 100%;
    text-align: center;
}

@media screen and (max-width: $mobile), screen and (max-width: $tablet) {
    .layer {
        height: auto;
    }

    .tv-text {
        font-size: 14px;
    }

    .header-container {
        height: 100%;
    }
}
