$mobile: 420px;
$tablet: 768px;

.container {
    margin: auto;
    min-height: calc(100vh - 120px);
    padding-bottom: 70px;
}
@media (min-width: 768px) {
    .container {
        width: 750px;
    }
}
@media (min-width: 992px) {
    .container {
        width: 970px;
    }
}
@media (min-width: 1200px) {
    .container {
        width: 1170px;
    }
}

img {
    max-width: 100%;
}

.row {
    display: flex;
}

.column {
    flex: 50%;
    justify-content: center;
    display: flex;
    align-items: center;
}

a {
    text-decoration: none;
    color: var(--theme-page-text);
    display: flex;
    align-items: center;
}

h2 {
    //margin-bottom: 25px;
    width: 100%;
    text-align: center;
}

ul {
    padding: 0;
}

li {
    list-style-type: none;
}

@media screen and (max-width: $mobile), screen and (max-width: $tablet) {
    .column {
        flex: none;
        display: flex;
        width: 100%;
        flex-wrap: wrap;
    }

    .row {
        display: grid;
    }

    .container {
        margin-right: 30px;
        margin-left: 30px;
    }
}
