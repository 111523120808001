@import "src/styles/common.scss";

.nft-container {
    display: flex;
    justify-content: center;
}

.nft-image {
    border-radius: 50%;
    border: 15px double var(--theme-button-border);
    filter: drop-shadow(0 0 40px var(--theme-button-background));
    display: flex;
    justify-content: center;
}

@media screen and (max-width: $mobile), screen and (max-width: $tablet) {
    .nft-image {
        max-width: 50%;
    }
}
