@import "src/styles/common.scss";

.faq-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    display: flex;
    height: 100%;
    padding-bottom: 70px;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.faq-wrapper {
    width: 80%;
    border: 10px double var(--theme-button-border);
    filter: drop-shadow(10px 10px 0px var(--theme-drop-shadow));
}

.faq-title {
    color: white;
    cursor: pointer;
    display: flex;
    min-height: 50px;
    padding: 10px 30px 10px 30px;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
    transition: 0.4s;
    border-bottom: 2px solid black;
    background-color: #6703fe;

    &:hover {
        background-color: var(--theme-button-background);
    }
}

.faq-description {
    padding: 10px 30px 10px 30px;
    background-color: #240159;
    min-height: 70px;
    display: flex;
    color: white;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
    -webkit-animation: fade-in 1s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    animation: fade-in 1s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@-webkit-keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@media screen and (max-width: $mobile), screen and (max-width: $tablet) {
    .faq-wrapper {
        width: 100%;
    }

    .faq-container {
        height: 100%;
    }
}
