@import "src/styles/common.scss";

.button-container {
    display: flex;
    justify-content: center;
    text-transform: uppercase;
    width: 100%;
}

.button {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 500px;
    width: 100%;
    height: 35px;
    letter-spacing: 3px;
    font-weight: bold;
    margin-bottom: 10px;
    border: 3px solid var(--theme-button-border);
    background-color: var(--theme-button-background);
    color: var(--theme-button-color);
    filter: drop-shadow(10px 10px 0px var(--theme-drop-shadow));
}

.button:hover {
    background-color: #6703fe;
    color: var(--theme-button-color);
    transition: 1s;
    border-color: var(--theme-button-border);
}

@media screen and (max-width: $mobile), screen and (max-width: $tablet) {
    .button {
        font-size: 24px;
    }
}
