.slider-title {
    font-size: 32px;
    margin-bottom: 10px;
}

.slider-value {
    font-size: 40px;
    margin-bottom: 15px;
    color: red;
}

.slidecontainer {
    width: 100%;
    margin-bottom: 15px;
}

.slider {
    -webkit-appearance: none;
    width: 100%;
    max-width: 506px;
    height: 30px;
    background: #d3d3d3;
    outline: none;
    opacity: 0.7;
    -webkit-transition: 0.2s;
    transition: opacity 0.2s;
}

.slider:hover {
    opacity: 1;
}

.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 30px;
    height: 30px;
    background: #ea008b;
    cursor: pointer;
}

.slider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    background: #4caf50;
    cursor: pointer;
}
