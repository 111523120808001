@import "src/styles/common.scss";

.header-container {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.random-reward-title {
    display: flex;
    flex-wrap: wrap;
}

.description-row {
    display: flex;
    flex-wrap: wrap;
}

.column-image {
    flex: 50%;
    justify-content: center;
    display: flex;
    align-items: center;
    position: relative;
}

.bubble-gif {
    position: absolute;
    top: -1%;
    right: 36%;
    height: 30%;
}

@media screen and (max-width: $mobile), screen and (max-width: $tablet) {
    .bubble-gif {
        position: absolute;
        top: -11%;
        right: 3%;
        height: 30%;
    }
}
