@import "src/styles/common.scss";

.nft-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 50px;
    justify-content: center;
}

.ntf-search-result-wrapper {
    width: 100%;
    flex-wrap: wrap;
    display: flex;
    justify-content: space-between;
}

.infinite-scroll {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.flip-card {
    background-color: transparent;
    width: 300px;
    height: 300px;
    perspective: 1000px;
    margin-bottom: 30px;
    margin-left: 20px;
    margin-right: 20px;
}

.flip-card-inner {
    margin-top: 20px;
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    box-shadow: 0 0 20px var(--theme-drop-shadow);
}

.flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    flex-direction: column;
}

.flip-card-back {
    background-color: var(--theme-navbar-background);
    color: white;
    transform: rotateY(180deg);
}

@-webkit-keyframes swirl-in-fwd {
    0% {
        -webkit-transform: rotate(-540deg) scale(0);
        transform: rotate(-540deg) scale(0);
        opacity: 0;
    }
    100% {
        -webkit-transform: rotate(0) scale(1);
        transform: rotate(0) scale(1);
        opacity: 1;
    }
}
@keyframes swirl-in-fwd {
    0% {
        -webkit-transform: rotate(-540deg) scale(0);
        transform: rotate(-540deg) scale(0);
        opacity: 0;
    }
    100% {
        -webkit-transform: rotate(0) scale(1);
        transform: rotate(0) scale(1);
        opacity: 1;
    }
}

@media screen and (max-width: $mobile), screen and (max-width: $tablet) {
    .img-container {
        width: 100%;
        justify-content: center;
    }

    .infinite-scroll {
        justify-content: center;
    }
}
