@import "src/styles/common.scss";

.nft-search-bar-container {
  position: sticky;
  width: 100%;
  height: 70px;
  z-index: 1;
  top: 0;
  background-color: var(--theme-navbar-background);
  -webkit-animation: fade-in-top 3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-top 3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.nft-search-form {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.nft-search-input {
  margin-left: 15px;
  height: 30px;
  font-family: "SH Pinscher";
  font-size: 18px;
  justify-content: center;
  text-align: center;
  background-color: white;
  border: 2px solid var(--theme-page-text);
  color: black;
  outline: none;
  width: 40%;
}

.nft-search-input-disabled {
  margin-left: 15px;
  height: 30px;
  font-family: "SH Pinscher";
  font-size: 18px;
  justify-content: center;
  text-align: center;
  background-color: #b8b8b8;
  border: 2px solid var(--theme-page-text);
  color: black;
  outline: none;
  width: 40%;
}

.nft-search-button {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 300px;
  width: 100%;
  height: 36px;
  margin-left: 30px;
  border: none;
  letter-spacing: 3px;
  font-weight: bold;
  background-color: var(--theme-button-background);
  color: var(--theme-button-color);
}

.nft-search-button:hover {
  background-color: #6703fe;
  color: var(--theme-button-color);
  transition: 1s;
}

@media screen and (max-width: $mobile), screen and (max-width: $tablet) {
  .nft-search-form {
    justify-content: space-evenly;
  }

  .nft-search-button {
    width: 40%;
    margin:0;
  }
  .nft-search-label {
    display: none;
  }
  .nft-search-input {
    margin: 0;
  }
}

@-webkit-keyframes fade-in-top {
  0% {
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes fade-in-top {
  0% {
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
