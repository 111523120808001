@import "src/styles/common.scss";

.generator-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    height: calc(100vh - 70px);
    align-items: center;
}

.generator-wrapper {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    height: 100%;
    width: 100%;
}

.generator-title {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 100%;
    text-align: center;
    align-items: center;
}

.generator {
    display: flex;
    flex-direction: column;
    text-align: center;
}

.generator-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.winner-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.prize-text {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.prize-title {
    font-size: 36px;
    font-weight: bold;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.checkbox-container {
    display: block;
    position: relative;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.tos {
    white-space: pre;
    color: var(--theme-button-background);
    font-weight: bold;
}

.checkbox-text {
    display: flex;
    justify-content: flex-start;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    right: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    filter: drop-shadow(10px 10px 0px var(--theme-drop-shadow));
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkmark {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
    background-color: #ea008b;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.prize {
    font-size: 36px;
    font-weight: bold;
    border: 2px solid var(--theme-page-text);
    width: 50%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.winner-image-wrapper {
    height: 100%;
    align-items: normal;
    display: flex;
    flex-direction: column;
    margin-top: 50px;
}

.winner-image {
    max-height: 300px;
}

@media screen and (max-width: $mobile), screen and (max-width: $tablet) {
    .generator {
        width: 100%;
    }

    .generator-container {
        padding-bottom: 100px;
        height: 100%;
    }
}
